import React from "react";
import { useRef, Suspense } from "react";
import "./index.css";
import { MotionCard } from "./MotionCard";
import { useDimensions } from "@chakra-ui/react";

import { Center, Flex, Spacer, Box } from "@chakra-ui/react";

const BoxPrintBefore = React.lazy(() =>
  import("./boxesBefore/BoxPrintBefore")
);
const BoxPrintAfter = React.lazy(() =>
  import("./boxesAfter/BoxPrintAfter")
);
const BoxMakeTimeAfter = React.lazy(() =>
  import("./boxesAfter/BoxMakeTimeAfter")
);
const BoxMakeTimeBefore = React.lazy(() =>
  import("./boxesBefore/BoxMakeTimeBefore")
);
const BoxTiskelnikBefore = React.lazy(() =>
  import("./boxesBefore/BoxTiskelnikBefore")
);
const BoxTiskelnikAfter = React.lazy(() => import("./boxesAfter/BoxTiskelnikAfter"));
const BoxProjectsBefore = React.lazy(() =>
  import("./boxesBefore/BoxProjectsBefore")
);
const BoxProjectsAfter = React.lazy(() =>
  import("./boxesAfter/BoxProjectsAfter")
);

const BoxContactAfter = React.lazy(() =>
  import("./boxesAfter/BoxContactAfter")
);
const BoxResumeBefore = React.lazy(() =>
  import("./boxesBefore/BoxResumeBefore")
);
const BoxResumeAfter = React.lazy(() => import("./boxesAfter/BoxResumeAfter"));
const BoxVojtechBefore = React.lazy(() =>
  import("./boxesBefore/BoxVojtechBefore")
);
const BoxVojtechAfter = React.lazy(() =>
  import("./boxesAfter/BoxVojtechAfter")
);
const BoxContactBefore = React.lazy(() =>
  import("./boxesBefore/BoxContactBefore")
);

const BoxProjectShare = React.lazy(() => import("./boxesBefore/BoxProjectShare"));
const BoxDelivexBefore = React.lazy(() => import("./boxesBefore/BoxDelivexBefore"));
const BoxDelivexAfter = React.lazy(() => import("./boxesAfter/BoxDelivexAfter"))

const Homepage = () => {
  const elementRef = useRef();
  const dimensions= useDimensions(elementRef);

  return (
    <Center w="100%" h="auto" overflow="hidden" zIndex="2">
      <Box
        position="absolute"
        top="0"
        left="0"
        w="100vw"
        h="100%"
        minH="100vh"
        zIndex="-1"
        backgroundImage= "linear-gradient(to right top, #7816f1, #820099, #650054, #3a0424, #000000)"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        filter="auto"
        brightness="40%"
      ></Box>
      <Flex
        direction="column"
        w={{ lg: "140vh", md: "120vh", sm: "100vw", base: "100vw" }} maxW={{ lg: '1200px', md: '1200px', sm: '100vw', base: '100vw'}}
        h="100%"
        p={5}
        ref={elementRef}
        overflow="hidden"
      >
        <Flex
          h={{ sm: "100vw", md: "30vh", lg: "30vh", base: "100vw" }}
          direction="row"
          flexWrap="wrap"
        >
          <Box
            w={{ sm: "100%", md: "50%", lg: "50%", base: "100%" }}
            h={{ md: "100%", lg: "100%", sm: "45vw", base: "45vw" }}
            p={2}
          >
            <MotionCard
              dimensions={dimensions}
              beforeClick={
                <Suspense fallback={"Loading"}>
                  <BoxVojtechBefore />
                </Suspense>
              }
              afterClick={
                <Suspense fallback={"Loading"}>
                  <BoxVojtechAfter />
                </Suspense>
              }
            />
          </Box>
          <Spacer></Spacer>
          <Box
            w={{ sm: "50%", md: "25%", lg: "25%", base: "50%" }}
            h={{ md: "100%", lg: "100%", sm: "45vw", base: "45vw" }}
            p={2}
          >
            <MotionCard
              dimensions={dimensions}
              beforeClick={
                <Suspense fallback={"Loading"}>
                  <BoxResumeBefore />
                </Suspense>
              }
              afterClick={
                <Suspense fallback={"Loading"}>
                  <BoxResumeAfter />
                </Suspense>
              }
            />
          </Box>
          <Spacer></Spacer>
          <Box
            w={{ sm: "50%", md: "25%", lg: "25%", base: "50%" }}
            h={{ md: "100%", lg: "100%", sm: "45vw", base: "45vw" }}
            p={2}
          >
            <MotionCard
              dimensions={dimensions}
              beforeClick={
                <Suspense fallback={"Loading"}>
                  <BoxMakeTimeBefore />
                </Suspense>
              }
              afterClick={
                <Suspense fallback={"Loading"}>
                  <BoxMakeTimeAfter />
                </Suspense>
              }
            />
          </Box>
        </Flex>
        <Spacer></Spacer>
        <Flex
          h={{ sm: "100vw", md: "30vh", lg: "30vh", base: "100vw" }}
          direction="row"
          flexWrap="wrap"
        >
          <Box
            w={{ sm: "50%", md: "25%", lg: "25%", base: "50%" }}
            h={{ md: "100%", lg: "100%", sm: "45vw", base: "45vw" }}
            p={2}
            flexWrap="wrap"
          >
            <MotionCard
              dimensions={dimensions}
              beforeClick={
                <Suspense fallback={"Loading"}>
                  <BoxTiskelnikBefore />
                </Suspense>
              }
              afterClick={
                <Suspense fallback={"Loading"}>
                  <BoxTiskelnikAfter />
                </Suspense>
              }
            />
          </Box>
          <Spacer></Spacer>
          <Box
            w={{ sm: "50%", md: "25%", lg: "25%", base: "50%" }}
            h={{ md: "100%", lg: "100%", sm: "45vw", base: "45vw" }}
            p={2}
          >
            <MotionCard
              dimensions={dimensions}
              beforeClick={
                <Suspense fallback={"Loading"}>
                  <BoxProjectsBefore />
                </Suspense>
              }
              afterClick={
                <Suspense fallback={"Loading"}>
                  <BoxProjectsAfter />
                </Suspense>
              }
            />
          </Box>
          <Spacer></Spacer>
          <Box
            w={{ sm: "100%", md: "50%", lg: "50%", base: "100%" }}
            h={{ md: "100%", lg: "100%", sm: "45vw", base: "45vw" }}
            p={2}
          >
            <MotionCard
              dimensions={dimensions}
              color="white"
              beforeClick={<Suspense fallback={"Loading"}>
                  <BoxProjectShare />
              </Suspense>}
              afterClick={null}
            />
          </Box>
        </Flex>
        <Spacer></Spacer>
        <Flex
          h={{ sm: "100vw", md: "30vh", lg: "30vh", base: "100vw" }}
          direction="row"
          flexWrap="wrap"
        >
          <Box
            w={{ sm: "100%", md: "50%", lg: "50%", base: "100%" }}
            h={{ md: "100%", lg: "100%", sm: "45vw", base: "45vw" }}
            p={2}
          >
            <MotionCard
              dimensions={dimensions}
              beforeClick={
                <Suspense fallback={"Loading"}>
                  <BoxContactBefore />
                </Suspense>
              }
              afterClick={
                <Suspense fallback={"Loading"}>
                  <BoxContactAfter />
                </Suspense>
              }
            />
          </Box>
          <Box
           w={{ sm: "50%", md: "25%", lg: "25%", base: "50%" }}
           h={{ md: "100%", lg: "100%", sm: "45vw", base: "45vw" }}
            p={2}
            pt={{ sm: 5, base: 5, md: 2, lg: 2 }}
          >
            <MotionCard
              dimensions={dimensions}
              beforeClick={
                <Suspense fallback={"Loading"}>
                  <BoxDelivexBefore />
                </Suspense>
              }
              afterClick={
                <Suspense fallback={"Loading"}>
                  <BoxDelivexAfter />
                </Suspense>
              }
            />
          </Box>
          <Box
      w={{ sm: "50%", md: "25%", lg: "25%", base: "50%" }}
      h={{ md: "100%", lg: "100%", sm: "45vw", base: "45vw" }}
            p={2}
            pt={{ sm: 5, base: 5, md: 2, lg: 2 }}
          >
            <MotionCard
              dimensions={dimensions}
              beforeClick={
                <Suspense fallback={"Loading"}>
                  <BoxPrintBefore />
                </Suspense>
              }
              afterClick={
                <Suspense fallback={"Loading"}>
                  <BoxPrintAfter />
                </Suspense>
              }
            />
          </Box>
        </Flex>
      </Flex>
    </Center>
  );
};

export default Homepage;
