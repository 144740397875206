import { animate, motion, AnimatePresence } from "framer-motion";
import React, { useState, Children, Fragment } from "react";
import {
  Center,
  Text,
  Flex,
  Spacer,
  Box,
  Container,
  Image,
  Icon,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export const MotionCard = ({ beforeClick, afterClick, dimensions, color }) => {
  const [isOpen, setIsOpen] = useState(false);
  const animDuration = 0.3;
  const animDelay = 0.3;

  return (
    <Fragment>
      {afterClick ? (
        <Fragment>
          <motion.div
            layout
            onClick={() => {
              if (!isOpen) {
                setIsOpen(!isOpen);
              }
            }}
            style={
              isOpen
                ? {
                    height: "100%",
                    width: "100%",
                    top: dimensions?.contentBox.top,
                    left: dimensions?.contentBox.left,
                    height: dimensions?.contentBox.height,
                    width: dimensions?.contentBox.width,
                    position: "absolute",
                    zIndex:'5'
                  }
                : { height: "100%", width: "100%", overflow: "hidden",  zIndex:'5'}
            }
            transition={{ duration: animDuration }}
            whileHover={{
              scale: 1.05,
              delay: 0,
              transition: { duration: 0.15 },
            }}
          >
            {isOpen ? (
              <Fragment>
                <Box
                  right={4}
                  top={4}
                  h={16}
                  w={16}
                  position="absolute"
                  cursor="pointer"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                 
                >
                  <Center w="100%" h="100%">
                    <CloseIcon w={5} h={5} color="gray.400" zIndex={100}/>
                  </Center>
                </Box>
                <Box
                  bg="white"
                  w="100%"
                  h="100%"
                  borderRadius={20}
                  shadow="sm"
                  p={4}
                  scrollBehavior="inside"
                  overflowY="scroll"
                  overflowX="hidden"
                  zIndex={5}
                >
                  <motion.div
                    key="afterCl"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: animDuration, delay: animDelay }}
                    style={{ height: "100%", width: "100%" }}
                  >
                    {afterClick}
                  </motion.div>
                </Box>
              </Fragment>
            ) : (
              <Box
                bg="white"
                w="100%"
                h="100%"
                borderRadius={20}
                shadow="sm"
                p={{base: 4, xl: 6}}
                cursor="pointer"
              >
                <motion.div
                  key="beforeCl"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: animDuration, delay: animDelay }}
                  style={{ height: "100%", width: "100%"}}
                >
                  {beforeClick}
                </motion.div>
              </Box>
            )}
          </motion.div>
        </Fragment>
      ) : (
        <motion.div whileHover={{
          scale: 1.05,
          delay: 0,
          transition: { duration: 0.15 },
          height: "100%", width: "100%"
        }} style={{ height: "100%", width: "100%", overflow: "hidden",  zIndex:'5'}}>
        <Box w="100%" h="100%" backgroundColor={color} borderRadius={20} shadow="sm" p={6} zIndex={4} overflow='hidden'>
          <motion.div
            key="beforeCl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: animDuration, delay: animDelay }}
            style={{ height: "100%", width: "100%"}}
          >
            {beforeClick}
          </motion.div>
        </Box>
        </motion.div>
      )}
    </Fragment>
  );
};
